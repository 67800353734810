@import "../../index";

.MessageContainer {
  top: 80px;
  right: 50px;
  height: 68px;
  display: flex;
  position: fixed;
  border-radius: 10px;
  box-shadow: 0 2px 11px 0 rgba(45, 44, 233, 0.14);
  background-color: #fff;
  align-items: center;
  animation: appearIn;
  animation-duration: 300ms;
  z-index: 10000;
  padding-right: 16px;
}

.Refresh {
  font-family: Roboto;
  font-size: 16px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.13;
  letter-spacing: normal;
  text-align: left;
  color: $pumpkin-orange;
  margin: 5px 0 4px 20px;
  background-color: transparent;
  padding: 0;
  border: none;
  cursor: pointer;
}

.MobileActions{
  display: none;
}

.MessageBody {
  display: initial;
  font-family: Roboto;
  font-size: 16px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.13;
  letter-spacing: normal;
  text-align: left;
  color: $warm-grey;
}

.MessageBodyMobile {
  display: none;
}

.orange-span {
  color: $pumpkin-orange;
}

.logo {
  width: 64px;
  height: 64px;
  margin: 0;
  object-fit: contain;
}

img.Close-icon {
  width: 20px;
  height: 20px;
  margin: 4px 20px 4px 40px;
  object-fit: contain;
  cursor: pointer;
}

@include smMaxBreakPoint {

  .MessageContainer {
    display: initial;
    width: 100%;
    height: 121px;
    top: 0;
    right: 0;
    border-radius: 10px;
    box-shadow: 0 2px 11px 0 rgba(45, 44, 233, 0.14);
    background-color: #fff;
    padding-right: 0;
  }

  .Refresh {
    display: none;
  }

  .Close-icon {
    display: none;
  }

  .logo {
    margin: 5px;
  }

  .MessageBody {
    display: none;
  }

  .MessageBodyMobile {
    display: initial;
    position: absolute;
    font-family: Roboto;
    font-size: 14px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.71;
    letter-spacing: normal;
    text-align: left;
    padding: 20px 20px 0 0;
    color: $warm-grey;
  }

  .MobileActions{
    margin: 9px 24px 16px 0;
    display: flex;
    justify-content: flex-end;
    -webkit-justify-content: flex-end;
  }

  .RefreshMobile {
    display: initial;
    font-family: Roboto;
    font-size: 14px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.29;
    letter-spacing: normal;
    text-align: center;
    color: $pumpkin-orange;
    background-color: transparent;
    border: none;
    padding: 0;
    margin: 0 0 0 20px;
    cursor: pointer;
  }

  .CloseMobile {
    display: initial;
    font-family: Roboto;
    font-size: 14px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.29;
    letter-spacing: normal;
    text-align: center;
    color: $warm-grey;
    background-color: transparent;
    border: none;
    padding: 0;
    cursor: pointer;
  }
}