@import "./variables";

.Button {
  display: -webkit-box;
  display: -webkit-flex;
  display: flex;
  align-items: center;
  justify-content: center;
  background: $pumpkin_orange;
  font-weight: bold;
  font-size: smaller;
  color: white;
  box-sizing: border-box;
  min-width: 220px;
  min-height: 33px;
  padding: 11px 28px;
  border-radius: 25px;
  cursor: pointer;
  text-decoration: none;
  border: none;
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none;
  white-space: nowrap;

  transition: border-color 0.3s ease, background-color 0.3s ease,
  color 0.3s ease, opacity 0.3s ease;

  &:hover {
    background: $pumpkin_orange_dark;
    border-color: $pumpkin_orange_dark;
  }

  &:disabled {
    background: $pumpkin_orange_disabled;
    border: $pumpkin_orange_disabled;
    cursor: not-allowed;
  }

  &:focus {
    outline: none;
  }

  &.Primary {
    border-color: $pumpkin_orange !important;
    background: $pumpkin_orange !important;
    color: white !important;
  }

  &.Primary-Outline {
    border: 2px solid #ff7420;
    color: #ff7420;
    background: white;
    height: 34px;

    &:hover {
      border-color: #ff7420;
      color: #ff7420;
    }

    &:disabled {
      opacity: 0.3;
    }
  }

  &.Primary-Select-Plan {
    width: 134px;
    height:33px;
    min-width:134px;
    min-height:33px;
    background-color: $pumpkin_orange;
  }

  &.Primary-Selected-Plan {
    width: 134px;
    height:33px;
    min-width:134px;
    min-height:33px;
    color:#fff;
    background-color: #d8d8d8;
    cursor: initial;
  }

  &.Secondary-Outline {
    min-width: 134px;
    height: 34px;
    object-fit: contain;
    border-radius: 25px;
    border: solid 2px #686868;
    background-color: #ffffff;
    padding: 0 18px;

    font-size: 14px;
    font-weight: bold;
    text-align: center;
    color: #686868;
    min-height: unset;

    &:hover {
      background: white;
      border-color: #686868;
    }
  }

  &.Warn {
    background-color: #c71818;
    color: #ffffff;
  }

  &.Small {
    min-width: 105px;
    min-height: 34px;
    width: 106px;
    height: 34px;
    padding: 10px 10px;
    box-sizing: border-box;
  }

  &.WhiteBorder {
    border: 2px solid white;
  }

  &.Disabled {
    opacity: 0.4;
    cursor: not-allowed;
    pointer-events: none;
    transition: opacity 0.2s;
  }

}

.ButtonDisabled {
  pointer-events: none;
  user-select: none;
  opacity: 0.4;
}


@media screen and (max-width: 900px) {
  .Button {
    min-width: 190px;
  }
}
