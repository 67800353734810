.CurrentlyOnHolidayModal {
  &.Hidden {
    display: none;
  }
  .ModalContainer {
    width: 530px;
    height: 276px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    gap: 32px;
    border-radius: 16px;

    .Close {
      top: 18px;
      right: 18px;
    }

    .Title {
      margin-top: 19px;
      margin-bottom: 0;
      height: 30px;

      /* Title Section Regular */
      font-family: "Roboto";
      font-style: normal;
      font-weight: 400;
      font-size: 22px;

      text-align: center;
      letter-spacing: 0.15px;

      /* Secondary */
      color: #767676;
    }

    .Note {
      width: 352px;
      height: 48px;
      margin-top: 3px;

      /* Text Regular */
      font-family: "Roboto";
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 24px;

      text-align: center;

      /* Secondary */
      color: #767676;
    }

    .Buttons {
      display: flex;
      justify-content: center;
      padding: 0;
      gap: 12px;

      .Button {
        width: 136px;
        height: 44px;
        font-size: 16px;
        letter-spacing: -0.5px;
      }
    }

    @media screen and (max-width: 900px) {
      max-width: 335px;
      height: 266px;
      padding-bottom: 32px;

      .Image {
        margin-top: 1px;
      }
      .Title {
        margin-top: 16px;
        font-size: 18px;
        letter-spacing: initial;
      }

      .Buttons {
        gap: 4px;
      }

      .Note {
        margin-top: 0;
      }
    }
  }
}
