@keyframes appearIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes zoomIn {
  from {
    transform: scale(0.3);
  }
  to {
    transform: scale(1);
  }
}

@keyframes FadeOutLeft {
  0% {
    transform: translateX(0);
    opacity: 1;

    @media (max-width: 500px) {
      transform: translateX(-50px);
    }
  }
  70% {
    transform: translateX(-50px);
    opacity: 0;
  }
  100% {
    opacity: 0;
    transform: translateX(-100px);
  }
}

@keyframes FadeOutRight {
  0% {
    transform: translateX(0);
    opacity: 1;

    @media (max-width: 500px) {
      transform: translateX(50px);
    }
  }
  70% {
    transform: translateX(50px);
    opacity: 0;
  }
  100% {
    opacity: 0;
    transform: translateX(100px);
  }
}

@keyframes FadeInRight {
  0% {
    transform: translateX(100px);
    opacity: 0;
  }
  100% {
    opacity: 1;
    transform: translateX(0);
  }
}

@keyframes FadeInLeft {
  0% {
    transform: translateX(-100px);
    opacity: 0;
  }
  100% {
    opacity: 1;
    transform: translateX(0);
  }
}

@keyframes SkeletonLoading {
  0% {
    background-position: 120% 50%;
  }
  100% {
    background-position: 0 50%;
  }
}

@keyframes FadeOut {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

@keyframes FadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes Heartbeat {
  0% {
    transform: scale(0.75);
  }
  20% {
    transform: scale(1);
  }
  40% {
    transform: scale(0.75);
  }
  60% {
    transform: scale(1);
  }
  80% {
    transform: scale(0.75);
  }
  100% {
    transform: scale(0.75);
  }
}

@keyframes toggleOnCheckbox {
  0% {
    opacity: 0;
    transform: translate(-50%, -85%) scale(0) rotate(45deg);
  }

  70% {
    opacity: 1;
    transform: translate(-50%, -85%) scale(0.9) rotate(45deg);
  }

  100% {
    transform: translate(-50%, -85%) scale(0.8) rotate(45deg);
  }
}
