@import "buttons";
@import "layout";
@import "animations";
@import "variables";
@import "mixins";

html {
  font-size: 16px;
}

input,
textarea,
button,
select,
a {
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

body {
  margin: 0;
  font-family: Roboto, sans-serif;
  background: #f4f6fc;
}

.NoSelect {
  // -webkit-touch-callout: none; /* iOS Safari */
  // -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none;
}

.TableWrapper {
  width: 100%;
  overflow-x: auto;
}

.Spacer {
  flex: 1;
}

.Toastify__toast-body {
  font-family: Roboto, sans-serif;
}

.Toastify__toast-container{
  z-index: 100000;
}

.DesktopHidden {
  display: none !important;
}

@media screen and (max-width: 900px) {
  .DesktopHidden {
    display: flex !important;
  }

  .MobileHidden {
    display: none !important;
  }
}

.loading {
  position: relative;
  &::after {
    display: block;
    content: "";
    position: absolute;
    width: 100%;
    height: 100%;
    @include skeleton-fill;
  }
}

.Tabs {
  position: relative;
  display: flex;
  top: 20px;

  .Tab {
    width: 120px;
    height: 70px;
    border-radius: 9px;
    margin-right: 2px;

    .Text {
      margin: 18px 10px;
    }

    &.Active {
      cursor: default;
      background-color: #fff;
      font-family: Roboto;
      font-size: 16px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.31;
      letter-spacing: normal;
      text-align: center;
      box-shadow: 0px -20px 16px -20px rgba(9, 44, 116, 0.16);
      z-index: 1;
    }

    &.Disable {
      cursor: pointer;
      font-size: 16px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.31;
      letter-spacing: normal;
      text-align: center;
      color: $warm-grey;
      box-shadow: 0 6px 16px 0 rgba(9, 44, 116, 0.16);
      border: solid 1px #d4d4d4;
    }
  }
}

