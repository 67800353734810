.Info {
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  &.Center {
    text-align: center;
    align-items: center;
  }

  .Title {
    font-size: 36px;
    line-height: 42px;
    margin-bottom: 40px;
    font-weight: bold;
  }
}

.Panel {
  align-items: center;
  justify-content: center;
  background: white;
  border-radius: 14px;
  padding: 35px 40px;
  box-sizing: border-box;
  box-shadow: 0.2px 0.2px 11px 0 rgba(45, 44, 233, 0.14);
  margin-top: -200px;
}

@media screen and (max-width: 900px) {
  .Info {
    .Title {
      font-size: 20px;
      margin-bottom: 20px;
    }

    .Description {
      font-size: 14px;
    }
  }
}
