@import '../../../index';

$color-marker: #ff7420;
$color-secondary: #767676;
$explore-chevron-transform: translateX(10px) scale(1.5) rotate(90deg);
$explore-chevron-transform-high-res: translateY(2.5px) translateX(-3px) scale(0.8);
$settings-chevron-transform: translateX(-5px) scale(1) rotate(90deg);

@mixin active-link {
  color: $color-marker;

  path[fill-rule="nonzero"] {
    fill: $color-marker;
  }
}

.PopupMenu,
.HeaderContainer {
  .Buttons {

    .Button,
    .Button:hover {
      background: $color-marker;
      border-color: $color-marker;
    }

    .Button.Primary-Outline,
    .Button.Primary-Outline:hover {
      border: 2px solid $color-marker !important;
      color: $color-marker !important;
      background: white !important;
    }
  }
}

.HeaderWrapperV2 {
  height: 60px;
  width: 100%;
  background: white;
  box-shadow: 0px 2px 16px rgba(0, 0, 0, 0.075);
  position: fixed;
  top: 0;
  z-index: 1001;

  &.Dashboard {
    box-shadow: 0.2px 0.2px 16px 0 rgba(0, 0, 0, 0.07);
  }

  &.UnLogged .HeaderContainer {
    .Menu {
      margin-right: 20px;

      .Links .Link {
        margin-right: 24px;
        margin-left: 1px;
      }
    }
  }

  .HeaderContainer {
    display: flex;
    align-items: center;
    justify-content: space-between;
    max-width: 1200px;
    height: 60px;
    margin: 0 auto;
    color: #7c7c85;

    .LogoContainerHeader {
      overflow: hidden;
      height: 100%;
      margin-left: 13px;

      .LogoImage {
        width: 110px;
        height: 40px;
        cursor: pointer;
        position: relative;
        top: 50%;
        transform: translateY(-50%);
      }
    }

    .Menu {
      display: flex;
      align-items: center;
      height: 100%;

      .Links {
        display: flex;
        align-items: center;
        height: 100%;

        .Link {
          font-family: 'Roboto';
          font-style: normal;
          font-weight: 700;
          font-size: 15px;
          line-height: 18px;
          margin-right: 27px;
          margin-left: -2px;
          cursor: pointer;
          text-decoration: none;
          color: $color-secondary;
          position: relative;
          display: flex;
          align-items: center;
          height: 100%;
          overflow: hidden;

          div {
            margin-right: -5px;
            margin-left: 5px;
          }

          &:hover {
            @include active-link
          }

          &.SubmenuActive {
            color: $color-marker;

            .exploreChevron {
              transform: $explore-chevron-transform-high-res rotate(-90deg);
              @include active-link
            }
          }

          &.Active::before,
          &.ActiveMenu::before {
            position: absolute;
            bottom: 0;
            left: 0;
            content: "";
            width: 92%;
            height: 3px;
            background-color: $color-marker;
          }

          &.ActiveMenu {
            @include active-link;
            color: $pumpkin_orange;
          }

          &.Active {
            color: $color-marker;
          }
        }
      }

      .Buttons {
        display: flex;
        position: relative;

        .Bell2 {
          margin-right: 27px;
          margin-top: 3px;
        }

        .SignUp {
          margin-right: 24px;
        }

        .ProfileSubmenu {
          position: absolute;
          background-color: white;
          height: 0;
          //transition: all .3s ease;
          overflow: hidden;
          top: 55px;
          left: 0;
          border-radius: 0 0 8px 8px;
          box-shadow: 0 2px 11px 0 rgba(45, 44, 233, 0.14);

          &:before {
            content: "";
            position: absolute;
            left: 4px;
            right: 0;
            top: -8px;
            margin: 0 auto;
            width: 0;
            height: 0;
            border-bottom: 8px solid white;
            border-left: 8px solid transparent;
            border-right: 8px solid transparent;
          }

          a {
            display: block;
            padding: 16px 22px 8px;
            text-decoration: none;
            font-size: 14px;
            font-weight: bold;
            color: $color-secondary;
            white-space: nowrap;

            &:visited {
              color: $color-secondary;
            }
          }
        }

        .ActiveSubmenu {
          box-shadow: inset 4px 0 0 $color-marker;
          padding-left: 10px;


        }

        .SubmenuOpenned {
          overflow: visible;
          height: fit-content;
          padding-bottom: 13px;
        }
      }

      .MobileCornerButtons {
        display: none;

        .Bell {
          margin-right: 1.94rem;
        }
      }
    }
  }

  .NotificationBadge {
    background-color: #ec4c4c;
    border-radius: 25px;
    font-size: 0.63rem;
    position: absolute;
    top: 1px;
    right: 24px;
    width: 14px;
    height: 14px;
    text-align: center;
    color: white;
    vertical-align: middle;
    display: flex;
    justify-content: center;
    align-items: center;
    border: solid 0.13rem #ffffff;

    @media (min-width: 1024px) {
      right: 16px;
    }
  }
}

.ExploreSubmenu {
  display: flex;
  position: relative;
  height: 100%;

  .exploreChevron {
    path[fill-rule="nonzero"] {
      fill: $color-secondary;
    }

    transform: $explore-chevron-transform-high-res rotate(90deg);
  }

  .Submenu {
    &:before {
      content: "";
      position: absolute;
      left: -6px;
      right: 0;
      top: -8px;
      margin: 0 auto;
      width: 0;
      height: 0;
      border-bottom: 8px solid white;
      border-left: 8px solid transparent;
      border-right: 8px solid transparent;
    }

    position: absolute;
    background-color: white;
    width: 143px;
    visibility: hidden;
    top: 77px;
    left: -45px;
    border-radius: 0 0 8px 8px;
    box-shadow: 0 2px 11px 0 rgba(45, 44, 233, 0.14);
    padding: 20px 28px 22px 20px;
    box-sizing: border-box;

    &.Footer {
      top: 40px;
    }

    div {
      width: 95px;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
    }

    a+a {
      margin-top: 1rem;
    }

    a {
      display: block;
      text-decoration: none;
      font-size: 0.938rem;
      font-weight: bold;
      white-space: nowrap;
      color: $color-secondary;

      &:hover {
        color: $color-marker !important;
      }

      &:visited {
        color: $color-secondary;
      }
    }
  }

  .ActiveSubmenu {
    color: $color-marker !important;
    position: relative;

    &::before {
      content: "";
      width: 0.19rem;
      height: 2.50rem;
      background-color: $color-marker;
      position: absolute;
      top: -11px;
      left: -20px;
    }
  }

  .SubmenuOpenned {
    visibility: visible;
  }
}

.PopupMenu {
  top: 0;
  left: 0;
  position: fixed;
  display: flex;
  flex-direction: column;
  z-index: 10000;
  background-color: white;
  width: 100%;
  height: 100%;
  padding: 0 40px;
  box-sizing: border-box;
  overflow: scroll;

  .OrangeXIcon {
    position: absolute;
    top: 30px;
    right: 30px;
    cursor: pointer;
  }

  .PopupMenuHeader {
    margin-bottom: 49px;
  }

  .TransparentLogo {
    width: 97px;
    height: 35px;
    position: absolute;
    top: 20px;
    left: 40px;
  }

  &.Authenticated {
    .Links {
      margin-bottom: 0;
    }
  }

  .Links {
    display: flex;
    flex-direction: column;
    margin-top: 73px;

    .Link {
      font-size: 18px;
      font-weight: bold;
      color: $color-secondary;
      margin-bottom: 34px;
      cursor: pointer;
      text-decoration: none;
      font-style: normal;
      line-height: 1.22;
      text-align: left;
      height: 22px;
      width: fit-content;
      font-family: Roboto;
      letter-spacing: normal;

      &:hover {
        color: $color-marker;
      }


      &:last-child {
        margin-bottom: 30px;
      }
    }
  }

  .Buttons {
    display: flex;
    justify-content: space-between;
    margin-top: 10px;

    .Button {
      margin-bottom: 20px;

      &:first-child {
        background: transparent !important;
      }
    }
  }

  .Circle {
    margin-bottom: 18px;
  }

  .ExploreSubmenuMovilContainer {
    margin-bottom: 10px;

    .Action.NoSelect.Active {
      margin-bottom: 0px;
    }
  }

  .ActiveMovilSubmenu {

    span,
    a {
      color: $color-marker;
    }

    .exploreChevron {
      path[fill-rule="nonzero"] {
        fill: $color-marker;
      }
    }
  }

  .ActiveMovilSubmenuItem {
    color: $color-marker !important;
  }

  .exploreChevron,
  .settingsChevron {
    path[fill-rule="nonzero"] {
      fill: $color-secondary;
    }

    transition-duration: 0.3s;
  }

  .exploreChevron {
    transform: $explore-chevron-transform;
  }

  .settingsChevron {
    transform: $settings-chevron-transform;
  }

  .MovilSubmenuItem {
    font-size: 16px;
    width: fit-content;
    margin-bottom: 30px;
  }

  .Actions {
    display: flex;
    flex-direction: column;

    .SettingsBox {
      display: flex;
      flex-direction: column;
      color: #ffffff;
      font-size: 0.88rem;
      font-weight: 500;
      font-stretch: normal;
      font-style: normal;
      margin: 25px 0 0 22px;

      a {
        text-decoration: none;
        color: $color-secondary;


        &:hover {
          color: $color-marker;
        }

        &:last-child {
          margin-bottom: 35px;
        }
      }

      .selectedItemSettingMenu {
        color: $color-marker;
      }
    }

    .Action {
      display: flex;
      align-items: center;
      font-size: 1rem;
      font-weight: bold;
      color: $color-secondary;
      margin-bottom: 16px;
      cursor: pointer;
      width: 100px;
      -webkit-tap-highlight-color: transparent;

      &:hover {
        color: $color-marker;

        path[fill-rule="nonzero"] {
          fill: $color-marker;
        }
      }

      &.Active {
        .exploreChevron {
          transform: $explore-chevron-transform rotateY(180deg);
        }

        .settingsChevron {
          transform: $settings-chevron-transform rotateY(180deg);
        }
      }

      &.selectedSettingMenu {
        path[fill-rule="nonzero"] {
          fill: $color-marker;
        }
      }

      span {
        margin-bottom: 5px;
      }

      span:last-child {
        margin-top: 15px;
        margin-bottom: 30px;
      }

      .ExploreTrigger {
        font-size: 18px;

        &::before {
          display: none;
        }
      }
    }
  }
}

.Disabled {
  opacity: 0.7;
  cursor: not-allowed !important;
  pointer-events: none;
}

.Circle {
  cursor: pointer;
  margin-right: 20px;

  &.Avatar {
    width: 28px;
    height: 28px;
    border-radius: 100%;
    object-fit: cover;
    background: #D8D8D8;
  }
}

.NotificationBell {
  position: relative;

  &.NewArrived {
    img {
      transform-origin: top;
      animation: shake 0.82s ease both;
      transform: rotate(0);
    }
  }

  @keyframes shake {

    10%,
    90% {
      transform: rotate(-1deg);
    }

    20%,
    80% {
      transform: rotate(4deg);
    }

    30%,
    50%,
    70% {
      transform: rotate(-6deg);
    }

    40%,
    60% {
      transform: rotate(6deg);
    }
  }
}

@media screen and (max-width: 900px) {
  .HeaderWrapperV2 {
    box-sizing: border-box;
    width: 100vw;

    .HeaderContainer {
      width: 100%;
      box-sizing: border-box;

      .LogoContainerHeader {
        margin-left: 10px;
      }

      .Logo {
        img {
          width: 86px;
          height: 23px;
        }
      }

      .Menu {
        margin-right: 25px !important;

        .Links,
        .Buttons,
        .Circle {
          display: none;
        }

        .MobileCornerButtons {
          display: flex;
          align-items: center;

          .PopupIcon {
            cursor: pointer;
          }

          .Bell {
            margin: 5px 35px 0 0;
          }
        }
      }
    }
  }
}

.ModalWrapper {
  &.SignOutModal {
    .ModalContainer {
      .Buttons {
        .Button {
          min-height: 33px;
          height: 33px;
          min-width: 100px;
          width: 134px;
        }
      }
    }
  }
}