@import "../../index";

.share-button-icon {
  align-items: center;
  display: flex;
  background-color: transparent;
  border: none;
  cursor: pointer;
  padding: 0;
  position: relative;
  outline: none;
  max-height: 24px;

  &.has-title {
    display: flex;
  }

  p {
    display: none;
    margin: 0 0 0 7px;
    font-family: Roboto;
    font-size: 14px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.29;
    letter-spacing: normal;
    text-align: left;
    color: $warm-grey;

    &.has-title {
      margin-left: 7px;
      display: initial;
    }
  }

  .share-button-icon_icon {
    &:hover:not(.share-button-icon__tooltip) {
      opacity: 0.85;
    }

    width: 24px;
    height: 24px;

    margin: 0;
  }

  .tooltip-copied__wrapper {
    position: absolute;
    margin-left: 0.7em;
    opacity: 0;
    transition: opacity 500ms ease-in-out;
    outline: none;

    bottom: 23px;
    left: 2%;
    transform: translateX(-50px);

    &:active {
      background: transparent;
    }

    &.show {
      opacity: 1;

      .tooltip-copied {
        display: flex;
      }
    }

    .tooltip-copied {
      width: 85px;
      height: 23px;
      margin: 0 0 5.7px;
      padding: 3px 5px 4px;
      border-radius: 4px;
      background-color: #767676;
      display: none;
      justify-content: center;
      align-items: center;

      span {
        font-size: 12px;
        font-weight: bold;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: 0.12px;
        text-align: center;
        color: #fff;
      }

      &::after {
        content: "";
        position: absolute;
        left: 0px;
        right: 0;
        bottom: 0;
        margin: 0 auto;
        width: 0;
        height: 0;
        border-bottom: 8px solid #767676;
        border-left: 8px solid transparent;
        border-right: 8px solid transparent;
        transform: rotate(180deg);
      }
    }
  }

  .share-button-icon__tooltip {
    padding: 0.7em;
    border-radius: 0.5rem;
    box-shadow: 0 0 1.63rem 0 rgba(0, 0, 0, 0.16);
    background-color: #ffffff;
    opacity: 0;
    position: absolute;
    top: -49px;
    z-index: 4;
    display: none;
    align-items: center;
    justify-content: space-evenly;
    right: 50%;
    transform: translateX(50%);
    line-height: 0;

    &.tooltip-visible {
      opacity: 1;
      display: flex;

      & > a + a {
        margin-left: 0.7em;
      }

      &.column {
        flex-direction: column;

        & > a + a {
          margin: 0.9em 0 0 0;
        }
      }

      .share-button-icon_icon p {
        color: $pumpkin_orange !important;
      }

      &.tooltip-down {
        bottom: unset;

        &::after {
          top: 36px;
          transform: rotate(180deg);
        }
      }
    }

    .tooltip-icon {
      width: 1.13rem !important;
      height: 1.13rem !important;
    }

    &::after {
      content: "";
      position: absolute;
      bottom: -0.6em;
      @media screen and (min-width: 768px) {
        right: unset;
      }
      margin: 0 auto;
      width: 0;
      height: 0;
      border-bottom: 8px solid white;
      border-left: 8px solid transparent;
      border-right: 8px solid transparent;
      transform: rotate(180deg);
    }
  }
}

@media screen and (max-width: 900px) {
  .share-button-icon {

    p {
      display: none;


      &.has-title {
        display: none;
      }
    }
  }
}
