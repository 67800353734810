@mixin timeSpan {
  width: 61px;
  height: 28px;
  padding: 4px 13px;
  border-radius: 0.56rem;
  box-shadow: 0 0 1.13rem 0 rgba(0, 0, 0, 0.16);
  background-color: #ffffff;
  box-sizing: border-box;
  font-size: 0.88rem;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  text-align: center;
  color: #686868;
}

.podcast-player__wrapper {
  --is-player-visible: none;

  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 71px;
  margin: 0.25rem 0 0;
  background-color: #ffffff;
  justify-content: space-between;
  align-items: center;
  z-index: 9999;
  box-sizing: border-box;
  border-top: 4px solid #d5d5d5;

  display: var(--is-player-visible);

  .podcast-player__wave-cursor {
    position: absolute;
    left: 0;
    top: -4px;
    bottom: 0;
    width: 0px;
    opacity: 1;
    border-top: 4px solid #ff7420;
    z-index: -1;

    &:before {
      content: "";
      position: absolute;
      opacity: 0.05;
      background: #ff7420;
      height: 71px;
      top: 0;
      left: 0;
      width: inherit;
    }
  }

  .podcast-player__waves-wrapper {
    position: absolute;
    left: 0;
    top: 37px;
    z-index: -1;
    width: 100%;

    .podcast-player__waves{
      opacity: var(--podcast-player-waves-opacity);
    }
  }

  & > section {
    height: 100%;
  }

  & section:nth-of-type(1) {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    min-width: var(--section-title-min-width);
    width: var(--section-title-width);
    order: var(--section-title-order);

    .podcast-player__picture {
      margin: 0 1.13rem 0 0;
      display: var(--info-picture-display);
      width: 71px;
      height: 71px;
      object-fit: cover;
    }

    .podcast-player__info-wrapper {
      h2 {
        margin: var(--info-title-margin);
        font-size: var(--info-title-font-size);
        font-weight: 500;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: normal;
        text-align: left;
        max-width: var(--info-title-max-width);
      }

      p {
        margin: var(--info-sub-title-margin);
        font-size: var(--info-sub-title-font-size);
        font-weight: 500;
        font-stretch: normal;
        font-style: normal;
        letter-spacing: normal;
        text-align: left;
        color: #767676;
        display: flex;
        justify-content: flex-start;

        & > span {
          display: var(--info-track-time-display);
        }
      }
    }
  }

  & section:nth-of-type(2) {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-evenly;
    margin: var(--section-controls-margin);

    max-width: var(--section-controls-max-width);
    min-width: var(--section-controls-min-width);
    width: var(--section-controls-max-width);
    order: var(--section-controls-order);

    .podcast-player__time-before {
      @include timeSpan;
      margin: 0;
      display: var(--time-before-display);
    }

    .podcast-player__controls-wrapper {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;

      @mixin hover-pointer-cursor {
        &:hover {
          cursor: pointer;
        }
      }

      @mixin hover-pointer-not-allowed {
        &:hover {
          cursor: not-allowed;
        }
        opacity: 0.3;
      }

      .podcast-player__rewind-control {
        width: 2rem;
        height: 2rem;
        margin: 0;
        object-fit: contain;
        transform: rotate(180deg);
        display: var(--rewind-control-display);

        &.grayed-and-not-allowed {
          @include hover-pointer-not-allowed;
        }

        @include hover-pointer-cursor;
      }

      .podcast-player__play-control {
        width: 2.75rem;
        height: 2.75rem;
        margin: var(--play-control-margin);
        object-fit: contain;
        @include hover-pointer-cursor;

        &:hover {
          transform: scale(1.06);
        }
      }

      .podcast-player__forward-control {
        width: 2rem;
        height: 2rem;
        margin: 0;
        object-fit: contain;
        @include hover-pointer-cursor;
        display: var(--forward-control-display);

        &.grayed-and-not-allowed {
          @include hover-pointer-not-allowed;
        }
      }
    }

    .podcast-player__time-after {
      @include timeSpan;
      margin: 0;
      display: var(--time-after-display);
    }
  }

  & section:nth-of-type(3) {
    display: var(--section-social-volumen-display);
    flex-direction: row;
    justify-content: space-between;
    max-width: 363px;
    width: 30%;
    order: var(--section-social-volumen-order);

    .podcast-player__social-wrapper {
      display: flex;
      align-items: center;

      .share-button-icon {
        .share-button-icon_icon {
          width: 24px;
          height: 24px;
        }

        &:last-child .share-button-icon_icon {
          width: 18px;
          height: 18px;
        }
      }

      button {
        margin: 0 1.53rem 0 0;
      }
    }

    .podcast-player__volume-wrapper {
      display: flex;
      justify-content: flex-end;
      align-items: center;
      margin: 0 3rem 0 0;

      .podcast-player__volume-icon {
        width: 1.63rem;
        height: 1.63rem;
        margin: 0 0.56rem 0 0;
        object-fit: contain;
      }

      .podcast-player__volume-slider {
        margin: 0 3rem 0 0;
      }
    }
  }

  .podcast-player__close {
    background: url(../../assets/img/cancel.svg) no-repeat center;
    width: 11.7px;
    height: 11.7px;
    position: absolute;
    top: 27px;
    right: 16px;
    cursor: pointer;
    filter: opacity(0.9);
  }
}

@media screen and (min-width: 320px) {
  .podcast-player__wrapper {
    --info-picture-display: none;
    --info-title-margin: 9px auto 3px 0px;
    --info-title-max-width:217px;
    --info-title-font-size: 12px;
    --info-sub-title-margin: 0 0 auto 0px;
    --info-sub-title-font-size: 12px;
    --info-sub-title-max-margin: 221px;
    --section-title-min-width: 220px;
    --time-before-display: none;
    --time-after-display: none;
    --rewind-control-display: none;
    --forward-control-display: none;
    --play-control-margin: 0;
    --section-social-volumen-display: none;
    --section-social-volumen-order: 3;
    --section-controls-margin: 0px 10px 0 10px;
    --section-controls-max-width: 61px;
    --section-controls-min-width: 27px;
    --section-controls-width: 8%;
    --info-track-time-display: inline-block;
    --section-title-width: 100%;
    --section-title-order: 2;
    --section-controls-order: 1;
    --podcast-player-waves-opacity: 0;
  }
}

@media screen and (min-width: 768px) {
  .podcast-player__wrapper {
    --info-picture-display: block;
    --info-title-margin: 12px 0 0.25rem 0;
    --info-title-font-size: 12px;
    --info-title-max-width: 100%;
    --info-sub-title-margin: 0 0 auto 0;
    --info-sub-title-font-size: 12px;
    --info-sub-title-max-margin: 30%;
    --section-title-min-width: 371px;
    --time-before-display: none;
    --time-after-display: none;
    --rewind-control-display: block;
    --forward-control-display: block;
    --play-control-margin: 0 26px;
    --section-social-volumen-display: none;
    --section-controls-margin: 0 84px 0 0;
    --section-controls-max-width: unset;
    --section-controls-min-width: unset;
    --section-controls-width: 40%;
    --info-track-time-display: inline-block;
    --section-title-width: 60%;
    --section-title-order:1;
    --section-controls-order:2;
    --section-social-volumen-order:3;
    --podcast-player-waves-opacity: 1;

  }
}

@media screen and (min-width: 1024px) {
  .podcast-player__wrapper {
    --info-picture-display: block;
    --info-title-margin: 12px 0 0.25rem 0;
    --info-sub-title-margin: 0 0 auto 0;
    --info-sub-title-max-margin: 30%;
    --info-title-max-width: 100%;
    --section-title-min-width: 371px;
    --time-before-display: block;
    --time-after-display: block;
    --rewind-control-display: block;
    --play-control-margin: 0 26px;
    --section-social-volumen-display: flex;
    --section-controls-margin: 0;
    --section-controls-max-width: 500px;
    --section-controls-min-width: 200px;
    --section-controls-width: 40%;
    --forward-control-display: block;
    --info-title-font-size: 0.88rem;
    --info-sub-title-font-size: 0.75rem;
    --info-track-time-display: none;
    --section-title-width: 32%;
    --section-title-order:1;
    --section-controls-order:2;
    --section-social-volumen-order:3;
    --podcast-player-waves-opacity: 1;
  }
}
