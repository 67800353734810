// COLORS
$warm-grey: #767676;
$pumpkin_orange: #ff7420;
$pumpkin_orange_lighter: rgba(255, 116, 32,0.2);
$pumpkin_orange_even_lighter: rgba(255, 116, 32,0.1);
$red: #c71818;
$pale-grey: #f4f6fc;
$skeleton-grey: #dddddd;
$light_orange: #f98e4d;
$pumpkin_orange_dark: #f06f21;
$pumpkin_orange_disabled: #ff742045;
$dark_grey: #2a2a2a;
$color-marker: #ff7420;
$yellow: #fab22a;

  // SCREEN SIZES
$screen-sm: 576px;
$screen-md: 768px;
$screen-lg: 992px;
$screen-xl: 1200px;
$screen-xxl: 1300px;
$screen-full: 1400px;

//TRANSFORMS
$explore-chevron-transform-high-res: translateY(2.5px) translateX(-3px) scale(0.8) ;
