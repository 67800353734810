@import "./variables";

@mixin skeleton-fill() {
  background: linear-gradient(
    90deg,
    rgba(221, 221, 221, 1) 0%,
    rgba(245, 245, 245, 1) 48%,
    rgba(221, 221, 221, 1) 100%
  );
  animation: SkeletonLoading 3s ease infinite;
  background-size: 400% 400%;
}

// Breakpoints
@mixin smMaxBreakPoint {
  @media (max-width: #{$screen-sm}) {
    @content;
  }
}

@mixin mdMinBreakPoint {
  @media (min-width: #{$screen-md}) {
    @content;
  }
}

@mixin lgMinBreakPoint {
  @media (min-width: #{$screen-lg}) {
    @content;
  }
}

@mixin xlMinBreakPoint {
  @media (min-width: #{$screen-xl}) {
    @content;
  }
}

@mixin xxlMinBreakPoint {
  @media (min-width: #{$screen-xxl}) {
    @content;
  }
}

@mixin customMinBreakPoint($size) {
  @media (min-width: $size+"px") {
    @content;
  }
}

@mixin customMaxBreakPoint($size) {
  @media (max-width: $size+"px") {
    @content;
  }
}

@mixin underlineTextButton($color) {
  font-size: 16px;
  font-weight: bold;
  letter-spacing: normal;
  color: $color;
  text-decoration: underline;
  background: transparent;
  outline: none;
  border: none;
  cursor: pointer;
}

@mixin fadeContainer($fade-width) {
  overflow: hidden;
  // border: 2px solid black;
  mask-image: linear-gradient(
      to right,
      transparent,
      black $fade-width,
      black calc(100% - $fade-width),
      transparent
    ),
    linear-gradient(white, white);
  mask-size: 100%, calc(100% - $fade-width * 2);
  mask-position: 0 0, $fade-width 0;
  mask-repeat: no-repeat, no-repeat;
}

@mixin defaultCalendarInputCalendar {
  .DayPicker-wrapper {
    top: 8px;
    width: 299px;
    box-shadow: 0px 33px 46px rgba(0, 0, 0, 0.161);
    border-radius: 8px;
    border: none;
    background: white;
    padding: 25px;
    z-index: 1000;

    @media (max-width: 768px) {
      width: 307px;
    }
  }

  .DayPicker-Month {
    margin: 0;
    width: 100%;
    height: 100%;
    border-collapse: separate;
    border-spacing: 2px;
  }

  .DayPicker-Caption {
    margin-bottom: 0;
    padding: 0;

    & > div {
      font-family: "Roboto";
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 24px;
      text-align: center;
      color: #000000;
    }
  }

  .DayPicker-NavButton--prev {
    top: 28px;
    left: 28px;
    height: 16px;
    width: 16px;
    margin-top: 0;
  }

  .DayPicker-NavButton--next {
    top: 28px;
    right: 28px;
    height: 16px;
    width: 16px;
    margin-top: 0;
  }

  .DayPicker-Weekday {
    font-size: 14px;
    font-weight: normal;
    color: $pumpkin_orange;
  }

  .DayPicker-Weekdays {
    display: flex;
    margin-top: 19px;
    padding: 0 5.5px;
    justify-content: center;

    @media (max-width: 768px) {
      padding: 0;
    }

    .DayPicker-WeekdaysRow {
      display: flex;
      gap: 12.6px;

      .DayPicker-Weekday {
        font-family: Roboto;
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 24px;
        text-align: center;
        color: $pumpkin_orange;
        width: 24px;
        height: 24px;
        display: flex;
        justify-content: center;
        align-items: center;

        padding: 0;
      }

      @media (max-width: 768px) {
        gap: 14px;
      }
    }
  }

  .DayPicker-Day {
    font-size: 14px;
    width: 32px;
    height: 32px;
    color: $warm-grey;

    &.DayPicker-Day--selected:not(.DayPicker-Day--disabled):not(.DayPicker-Day--outside) {
      color: white;
      background-color: $pumpkin_orange;
    }
  }

  .DayPicker-Body {
    .DayPicker-Week {
      display: flex;
      gap: 9.33px;
      margin: 5.5px 5.5px 8px;

      @media (max-width: 768px) {
        gap: 11px;
      }

      .DayPicker-Day {
        font-family: Roboto;
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 24px;
        text-align: center;
        color: #767676;
        width: 24px;
        height: 24px;
        display: flex;
        justify-content: center;
        align-items: center;
        margin: 0 1.5px;

        padding: 0;

        &:not(.DayPicker-Day--selected):hover,
        .hover {
          color: $pumpkin_orange;
          background-color: initial !important;
        }

        &.DayPicker-Day--disabled:not(.DayPicker-Day--outside) {
          color: #d8d8d8;
        }
      }

      &:last-child {
        margin-bottom: 0;
      }

      .DayPicker-Day--outside {
        color: #d8d8d8;
      }
    }
  }
}
